<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="일상점검 결과"
          :isTitle="true"
          :merge="grid.merge"
          :columns="grid.columns"
          :data="grid.data"
          :gridHeightAuto="true"
          :editable="!disabled"
          :columnSetting="false"
          :filtering="false"
          :usePaging="false"
          selection="multiple"
          rowKey="dailyCheckResultId"
          @headerDataChange="headerDataChange"
        >
          <template slot="suffixTitle">
            <font class="text-negative" color="red"  style="font-size:0.8em;font-weight:300;">
              <q-icon name="radio_button_unchecked" color="red"  class="text-black"/>정상
              <q-icon name="close" color="red" class="text-black"/>이상
            </font>
          </template>
          <template slot="table-button">
            <q-btn-group>
              <c-btn label="정비요청" v-if="editable" icon="add" @btnClicked="linkClick3" />
              <c-btn
                v-if="editable && !disabled && !data.sysApprovalRequestId"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data.dailyCheckId ? this.grid.data : this.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'customDetail'">
              <q-btn
                flat
                size="12px"
                color="orange"
                icon="chevron_right"
                @click="rowClickDetail(props.row, props.pageIndex)" />
            </template>
            <template v-else-if="col.name === 'cycle1'">
              <span v-if="props.row['cycle1'] === 'Y'">
                {{ '●' }}
              </span>
            </template>
            <template v-else-if="col.name === 'cycle2'">
              <span v-if="props.row['cycle2'] === 'Y'">
                {{ '●' }}
              </span>
            </template>
            <template v-else-if="col.name === 'cycle3'">
              <span v-if="props.row['cycle3'] === 'Y'">
                {{ '●' }}
              </span>
            </template>
            <template v-else-if="props.row['cbmFlag'] === 'N'">
            </template>
            <template v-else-if="col.name === 'gaugeWarn'">
              <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
                {{ props.row.gaugeWarnLcl}}
              </font>
              <font style="font-size:1.0em;font-weight:700;">
                ~
              </font>
              <font style="font-size:1.0em;font-weight:700;" color="#C10015">
                {{ props.row.gaugeWarnUcl}}
              </font>
            </template>
            <template v-else-if="col.name === 'gaugeExec'">
              <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
                {{ props.row.gaugeExecLcl}}
              </font>
              <font style="font-size:1.0em;font-weight:700;">
                ~
              </font>
              <font style="font-size:1.0em;font-weight:700;" color="#C10015">
                {{ props.row.gaugeExecUcl}}
              </font>
            </template>
            <template v-else-if="col.name === 'cbmAlert'">
              <q-chip
                :color="setTagColor(props.row)"
                :clickable="false"
                :class="null"
                text-color="white">
                {{setTagName(props.row)}}
              </q-chip>
            </template>
            <template v-else-if="col.name === 'day' + today + 'C'">
              <c-number-column
                :editable="editable"
                :col="col"
                :props="props"
                v-model="props.row['day' + today + 'C']"
                @datachange="datachange(props, col)"
              />
            </template>
          </template>
        </c-table>
      </div>  
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <q-dialog v-model="dialogDetail" :position="positionDetail">
      <q-card style="width: 100%">
        <q-linear-progress :value="1" color="pink" />
        <q-card-section class="row">
          <div class="col-12 text-weight-bold-dailog" v-text="selectedRow.checkItemName"></div>
          <div class="col-4"><div class="mini-dailog-title">부품</div></div>
          <div class="col-8" v-text="selectedRow.checkItemPartName"></div>
          <!-- <div class="col-4"><div class="mini-dailog-title">이달최근점검</div></div>
          <div class="col-8" v-text="selectedRow.recentDay"></div> -->
          <div class="col-4"><div class="mini-dailog-title">개선진행 상태</div></div>
          <div class="col-8 mini-dailog-btn-impr">
            <div v-if="editable && !disabled">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !disabled&&selectedRow.dailyCheckResultId"
                  :showLoading="false"
                  label="개선요청"
                  color="red-6"
                  text-color="white"
                  @btnClicked="innerBtnClicked(selectedRow, '1')"
                />
                <c-btn
                  v-if="editable && !disabled&&selectedRow.dailyCheckResultId"
                  :showLoading="false"
                  label="즉시조치"
                  color="light-blue"
                  text-color="white"
                  @btnClicked="innerBtnClicked(selectedRow, '2')"
                />
              </q-btn-group>
            </div>
          </div>
          <div class="col-12 border-impr-dialog">
            <template v-if="selectedRow['sopImprovementIds'] && selectedRow['ibmStepNames']">
              <q-chip
                v-for="(item, index) in $comm.ibmTagItems(selectedRow)"
                :key="index"
                :color="item.color"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click="linkClick(item, selectedRow)">
                {{item.title}}
                <!-- <c-assess-tooltip 
                  :ibmTooltip="item.ibmTooltip"
                  :ibmClassCd="item.ibmClassCd"
                /> -->
              </q-chip>
            </template>
            <template v-else-if="!selectedRow['dailyCheckResultId']">
              저장 후 개선요청이 가능합니다.
            </template>
            <template v-else>
              등록된 개선요청 및 즉시조치가 없습니다.
            </template>
          </div>
          <div class="col-12 mini-dailog-btn-impr">
            <q-btn-group outline>
              <c-btn
                :showLoading="false"
                label="닫기"
                icon="close"
                @btnClicked="dialogClose"
              />
              <!-- <c-btn
                v-if="editable && !disabled"
                :showLoading="false"
                label="적용"
                icon="check"
                color="amber-7"
                @btnClicked="dialogSubmit"
              /> -->
            </q-btn-group>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import mobileConfig from '@/js/mobileConfig';
export default {
  name: 'equipment-daily-result',
  props: {
    data: {
      type: Object,
      default: () => ({
        dailyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        lineId: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        dailyName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        utilityFlag: 'N',
        dailyResultModelList: [], // sheet
        dailyImproveModelList: [], 
      }),
    },
    grid: {
      type: Object,
      default: () => ({
        height: 'auto',
        columns: [],
        data: [],
      }),
    },
    grid2: {
      type: Object,
      default: () => ({
        height: 'auto',
        columns: [],
        data: [],
      }),
    },
  },
  data() {
    return {
      dialogDetail: false,
      positionDetail: 'bottom',
      selectedRow: {},
      selectedRowIdx: -1,
      year: '',
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      insertUrl: '',
      updateUrl: '',
      isSave: false,
      isSave2: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      mappingType2: 'POST',
      currentMonth: '',
      lunarHolidays: [],
      today: '',
      checkboxItems: [
        // { code: null, codeName: ''},
        { code: 'CHK001', codeName: 'o'},
        { code: 'CHK002', codeName: 'X'},
      ],
      colorItems: [
        { name: '경고', color: 'red' },
        { name: '정비요청필요', color: 'orange' },
        { name: '정상', color: 'blue' },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (String(this.today.charAt(0)) === '0') {
        this.today = this.today.substring(1)
      }
      // url setting
      this.getUrl = mobileConfig.sop.min.equipment.plan.daily.get.mobile.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.daily.newItem.url;
      // code setting
      // list setting
    },
   
    saveData() {
      if (this.data.dailyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;

          this.$_.forEach(this.grid.data, _item => {
            _item.regUserId = this.$store.getters.user.userId;
            _item.chgUserId = this.$store.getters.user.userId;
            if (!_item['day'+this.today]) {
              this.$set(_item, 'day'+this.today, _item.remark)
            }
          })

          if (this.mappingType === 'POST') {
            this.$_.forEach(this.grid.data, _item => {
              this.$_.forEach(this.grid.data, __item => {
                if(__item.dailyCheckResultId === _item.dailyCheckResultId) {
                  __item = _item;
                }
              })
            })
          } else if (this.mappingType === 'PUT') {
            this.grid.data = this.$_.clone(this.grid.data);
          } 

          this.data.utilityFlag = 'N';
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
   
    saveData2() {
      if (this.data.dailyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType2 = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType2 = 'POST';
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;

          this.$_.forEach(this.grid2.data, _item => {
            _item.regUserId = this.$store.getters.user.userId;
            _item.chgUserId = this.$store.getters.user.userId;
            if (!_item['day'+this.today]) {
              this.$set(_item, 'day'+this.today, _item.remark)
            }
          })

          if (this.mappingType2 === 'POST') {
            this.$_.forEach(this.grid2.data, _item => {
              this.$_.forEach(this.grid2.data, __item => {
                if(__item.dailyCheckResultId === _item.dailyCheckResultId) {
                  __item = _item;
                }
              })
            })
          } else if (this.mappingType2 === 'PUT') {
            this.grid2.data = this.$_.clone(this.grid2.data);
          } 

          this.data.utilityFlag = 'N';
          this.isSave2 = !this.isSave2;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType === 'POST') {
        this.data.dailyCheckId = result.data
        this.returnData.col1 = result.data
        this.$_.forEach(this.grid.data, _item => {
          _item.dailyCheckId = result.data;
          _item.editFlag = 'U';
        })
        this.$_.forEach(this.grid2.data, _item => {
          _item.dailyCheckId = result.data;
          _item.editFlag = 'U';
        })
      }
    },
    datachange2(props) {
      props.row['remark'] = null;
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    datachange1(props) {
      props.row['day' + this.today] = null;
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    headerDataChange(props, col) {
      if (col.name === String('day' + this.today)) {
        this.$_.forEach(this.grid.data, _item => {
          _item.remark = '';
        })
      } else if (col.name === 'day' + this.today + 'C') {
        this.$_.forEach(this.grid2.data, _item => {
          this.$set(_item, 'day' + this.today + 'C', null)
        })
      }
    },
    innerBtnClicked(row, btn) {
      this.requestImprRow = row
      let requestContents = row.checkItemName
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: row.dailyCheckResultId+"/"+'day'+this.today,
        ibmTaskTypeCd: 'ITT0000070',
        };
        this.popupOptions.target = () => import(`${'@/pages/ibm/imprDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: row.dailyCheckResultId+"/"+'day'+this.today,
        ibmTaskTypeCd: 'ITT0000070',
        };
        this.popupOptions.target = () => import(`${'@/pages/ibm/imprImmDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/ibm/imprDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/ibm/imprImmDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      // this.getDetail();
      if (data && data.col1) {
        let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
        if (sData.indexOf(data.col1.sopImprovementId) === -1) {
          this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
            this.requestImprRow.sopImprovementIds + ',' + data.col1.sopImprovementId : data.col1.sopImprovementId
          this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
            this.requestImprRow.ibmStepNames + ',' + data.col1.ibmStepName : data.col1.ibmStepName
          this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
            this.requestImprRow.ibmClassCds + ',' + data.col1.ibmClassCd : data.col1.ibmClassCd
          this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
            this.requestImprRow.ibmTooltip + '│' + data.col1.ibmTooltip : data.col1.ibmTooltip
        }
        this.$refs['grid'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      // this.getDetail();

      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          let splitTooltip = this.$_.split(this.requestImprRow.ibmTooltip, '│');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            splitTooltip.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
            this.$set(this.requestImprRow, 'ibmTooltip', splitTooltip.join('│'))
          }
        }
        this.$refs['grid'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    rowClickDetail(_row, _idx) {
      this.dialogDetail = true;
      this.selectedRow = _row;
      this.selectedRowIdx = _idx;
    },
    dialogSubmit() {
      this.dialogDetail = false;
    },
    dialogClose() {
      this.dialogDetail = false;
    },
    setTagColor(row) {
      if (Number(row.gaugeExecLcl) >= Number(row['day'+this.today + 'C']) || Number(row.gaugeExecUcl) <= Number(row['day'+this.today + 'C'])) {
        return 'red'
      } else if (Number(row.gaugeWarnLcl) >= Number(row['day'+this.today + 'C']) || Number(row.gaugeWarnUcl) <= Number(row['day'+this.today + 'C'])) {
        return 'orange'
      } else {
        return 'blue'
      }
    },
    setTagName(row) {
      if (Number(row.gaugeExecLcl) >= Number(row['day'+this.today + 'C']) || Number(row.gaugeExecUcl) <= Number(row['day'+this.today + 'C'])) {
        return '정비요청필요'
      } else if (Number(row.gaugeWarnLcl) >= Number(row['day'+this.today + 'C']) || Number(row.gaugeWarnUcl) <= Number(row['day'+this.today + 'C'])) {
        return '경고'
      } else {
        return '정상'
      }
    },
    linkClick3() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '점검항목을 선택하세요.', // 공기구를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let row = selectData[0] // 대표설비
        let title = row.equipmentName;
        this.$_.forEach(selectData, _item => {
          title +=  '|' + _item.checkItemPartName + '|' + _item.checkItemName + '☞' + _item.cbmTypeName
           + '[경고값: ' + _item.gaugeWarnLcl + '~' + _item.gaugeWarnUcl + '|실행값: ' + _item.gaugeExecLcl + '~' + _item.gaugeExecUcl +']';
        })
        
        this.popupOptions.title = '작업요청 상세';
        this.popupOptions.param = {
          woRequestId: '',
          plantCd: '',
          dailyCheckId: this.data.dailyCheckId,
          equipmentCd: row.equipmentCd,
          remark: title,
          woRequestName: this.today + ' 일상점검에 대한 정비요청',
          funcLocationCd: row.funcLocationCd,
          costCenterCd: row.costCenterCd,
          woRequestDt: this.today,
          woWorkTypeCd: 'WWTC000007',
        };
        this.popupOptions.target = () => import(`${'@/pages/wod/workOrderRequestDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.width = '90%';
        this.popupOptions.closeCallback = this.closePopup;
      }

      
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
