var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "일상점검 결과",
                  isTitle: true,
                  merge: _vm.grid.merge,
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  gridHeightAuto: true,
                  editable: !_vm.disabled,
                  columnSetting: false,
                  filtering: false,
                  usePaging: false,
                  selection: "multiple",
                  rowKey: "dailyCheckResultId",
                },
                on: { headerDataChange: _vm.headerDataChange },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "customDetail"
                          ? [
                              _c("q-btn", {
                                attrs: {
                                  flat: "",
                                  size: "12px",
                                  color: "orange",
                                  icon: "chevron_right",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowClickDetail(
                                      props.row,
                                      props.pageIndex
                                    )
                                  },
                                },
                              }),
                            ]
                          : col.name === "cycle1"
                          ? [
                              props.row["cycle1"] === "Y"
                                ? _c("span", [_vm._v(" " + _vm._s("●") + " ")])
                                : _vm._e(),
                            ]
                          : col.name === "cycle2"
                          ? [
                              props.row["cycle2"] === "Y"
                                ? _c("span", [_vm._v(" " + _vm._s("●") + " ")])
                                : _vm._e(),
                            ]
                          : col.name === "cycle3"
                          ? [
                              props.row["cycle3"] === "Y"
                                ? _c("span", [_vm._v(" " + _vm._s("●") + " ")])
                                : _vm._e(),
                            ]
                          : props.row["cbmFlag"] === "N"
                          ? void 0
                          : col.name === "gaugeWarn"
                          ? [
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                  attrs: { color: "#0300c1" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.row.gaugeWarnLcl) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                },
                                [_vm._v(" ~ ")]
                              ),
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                  attrs: { color: "#C10015" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.row.gaugeWarnUcl) + " "
                                  ),
                                ]
                              ),
                            ]
                          : col.name === "gaugeExec"
                          ? [
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                  attrs: { color: "#0300c1" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.row.gaugeExecLcl) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                },
                                [_vm._v(" ~ ")]
                              ),
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                  attrs: { color: "#C10015" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.row.gaugeExecUcl) + " "
                                  ),
                                ]
                              ),
                            ]
                          : col.name === "cbmAlert"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  class: null,
                                  attrs: {
                                    color: _vm.setTagColor(props.row),
                                    clickable: false,
                                    "text-color": "white",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.setTagName(props.row)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : col.name === "day" + _vm.today + "C"
                          ? [
                              _c("c-number-column", {
                                attrs: {
                                  editable: _vm.editable,
                                  col: col,
                                  props: props,
                                },
                                on: {
                                  datachange: function ($event) {
                                    return _vm.datachange(props, col)
                                  },
                                },
                                model: {
                                  value: props.row["day" + _vm.today + "C"],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      props.row,
                                      "day" + _vm.today + "C",
                                      $$v
                                    )
                                  },
                                  expression: "props.row['day' + today + 'C']",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "suffixTitle" },
                  [
                    _c(
                      "font",
                      {
                        staticClass: "text-negative",
                        staticStyle: {
                          "font-size": "0.8em",
                          "font-weight": "300",
                        },
                        attrs: { color: "red" },
                      },
                      [
                        _c("q-icon", {
                          staticClass: "text-black",
                          attrs: {
                            name: "radio_button_unchecked",
                            color: "red",
                          },
                        }),
                        _vm._v("정상 "),
                        _c("q-icon", {
                          staticClass: "text-black",
                          attrs: { name: "close", color: "red" },
                        }),
                        _vm._v("이상 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "정비요청", icon: "add" },
                              on: { btnClicked: _vm.linkClick3 },
                            })
                          : _vm._e(),
                        _vm.editable &&
                        !_vm.disabled &&
                        !_vm.data.sysApprovalRequestId
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.data.dailyCheckId
                                  ? this.grid.data
                                  : this.data,
                                mappingType: "POST",
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveData,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c(
        "q-dialog",
        {
          attrs: { position: _vm.positionDetail },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c("q-card-section", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-12 text-weight-bold-dailog",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.checkItemName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("부품"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.checkItemPartName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("개선진행 상태"),
                  ]),
                ]),
                _c("div", { staticClass: "col-8 mini-dailog-btn-impr" }, [
                  _vm.editable && !_vm.disabled
                    ? _c(
                        "div",
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.selectedRow.dailyCheckResultId
                                ? _c("c-btn", {
                                    attrs: {
                                      showLoading: false,
                                      label: "개선요청",
                                      color: "red-6",
                                      "text-color": "white",
                                    },
                                    on: {
                                      btnClicked: function ($event) {
                                        return _vm.innerBtnClicked(
                                          _vm.selectedRow,
                                          "1"
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.selectedRow.dailyCheckResultId
                                ? _c("c-btn", {
                                    attrs: {
                                      showLoading: false,
                                      label: "즉시조치",
                                      color: "light-blue",
                                      "text-color": "white",
                                    },
                                    on: {
                                      btnClicked: function ($event) {
                                        return _vm.innerBtnClicked(
                                          _vm.selectedRow,
                                          "2"
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 border-impr-dialog" },
                  [
                    _vm.selectedRow["sopImprovementIds"] &&
                    _vm.selectedRow["ibmStepNames"]
                      ? _vm._l(
                          _vm.$comm.ibmTagItems(_vm.selectedRow),
                          function (item, index) {
                            return _c(
                              "q-chip",
                              {
                                key: index,
                                staticStyle: {
                                  "margin-bottom": "4px !important",
                                },
                                attrs: {
                                  color: item.color,
                                  clickable: true,
                                  "text-color": "white",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.linkClick(item, _vm.selectedRow)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            )
                          }
                        )
                      : !_vm.selectedRow["dailyCheckResultId"]
                      ? [_vm._v(" 저장 후 개선요청이 가능합니다. ")]
                      : [_vm._v(" 등록된 개선요청 및 즉시조치가 없습니다. ")],
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "col-12 mini-dailog-btn-impr" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "닫기",
                            icon: "close",
                          },
                          on: { btnClicked: _vm.dialogClose },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }